import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/suriname-900.jpg";
export const meta = {
  name: "suriname",
  title: "A Corrida do Ouro Ameaça o País mais Verde do Mundo",
  intro: "Os cursos de água e os ventos do Suriname transportam mercúrio, um metal tóxico utilizado na extração de ouro, para regiões distantes das minas de ouro do país.",
  by: "Por Bram Ebus (textos e fotos) e Wilfred Leeuwin (textos). Vídeos por Tom Laffay",
  image: Image,
  image: Image,
  next: {
    slug: "venezuela",
    title: "Nas margens de Cuyuní, o mercúrio brilha mais do que o ouro",
    description: "O mercúrio é mais seguro e mais lucrativo para o comércio do que o próprio ouro. Quanto mais perto você chegar da Guiana, mais barato você encontrará o procurado \"quicksilver\"."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Homens, em ritmo`}</strong>{` acelerado, carregando pesadas bolsas esportivas com ouro, passam pela entrada de uma das lojas de ouro no centro de Paramaribo, a capital do Suriname. O vigia, um homem de óculos escuros e bermuda, observa do estacionamento e sua mão repousa na pochete, presa sobre o seu peito. Lá dentro, os idiomas chinês, português e holandês podem ser ouvidos,  à medida que os quilogramas de ouro passam de mão em mão. Ninguém pergunta aos homens de onde o ouro foi extraído antes deles saírem da loja. As bolsas agora estão pesadas com dinheiro vivo.`}</p>
    <p>{`No balcão, Harry Souza*, um brasileiro musculoso, mostra suas tatuagens e um pequeno balde de metal cheio de barras de ouro em diferentes cores e tamanhos.  Existem até algumas "esponjas", outro nome para o amálgama de mercúrio e ouro vindo diretamente das minas e parecido com um luxuoso recife de coral. “Quanto mais vermelho, maior a qualidade”, diz Souza. “Quanto mais verde ou preto, menos `}{`[valioso]`}{`.” Nos fundos da loja de ouro, um maçarico cospe chamas e Souza retorna para queimar o excesso de mercúrio sob um exaustor. A quantidade de ouro deixada para trás no balde pesa cerca de um quilograma - um valor de mercado de mais de US$ 62.000 (preço em 4 de setembro)`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeFfmlKhCf/EABYQAAMAAAAAAAAAAAAAAAAAAAAgMv/aAAgBAQABBQIlf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFJ/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAgIf/aAAgBAQAGPwKaFf/EABoQAAMAAwEAAAAAAAAAAAAAAAABERAhMYH/2gAIAQEAAT8hk4NtFVfRvfEUuP/aAAwDAQACAAMAAAAQzP8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Qir//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCiP//EABoQAQEBAQADAAAAAAAAAAAAAAERACFBUWH/2gAIAQEAAT8QEirTp5MEx1HmGtIfDXd6Zl3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/e93cc/1.webp 300w", "/static/a4264e07ca9280913466a07557b380e2/b0544/1.webp 600w", "/static/a4264e07ca9280913466a07557b380e2/68fc1/1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a4264e07ca9280913466a07557b380e2/73b64/1.jpg 300w", "/static/a4264e07ca9280913466a07557b380e2/3ad8d/1.jpg 600w", "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a4264e07ca9280913466a07557b380e2/6c738/1.jpg",
              "alt": "Gold mining is the driving force of the Surinamese economy",
              "title": "Gold mining is the driving force of the Surinamese economy",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Gold mining is the driving force of the Surinamese economy`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB41USNyMR/8QAGRAAAwADAAAAAAAAAAAAAAAAAAECAxAR/9oACAEBAAEFAlDZDnEibqRvuv/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARn/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BTZ//xAAaEAACAgMAAAAAAAAAAAAAAAABIQIQABFB/9oACAEBAAY/Ak8nGYZGnykb/8QAHBABAAEEAwAAAAAAAAAAAAAAAQAQESFhMVFx/9oACAEBAAE/IeMK0TqswoQu55FyYOif/9oADAMBAAIAAwAAABDLP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EIH/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAgEBPxCA/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARMSFhUXGB/9oACAEBAAE/EHAW4WEcy4Cq68RRV7liGGqou8i6vK/YIVd7J//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/e93cc/2.webp 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/b0544/2.webp 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/68fc1/2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f59851e8320e6e8a295af8ef7f22cf0c/73b64/2.jpg 300w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/3ad8d/2.jpg 600w", "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f59851e8320e6e8a295af8ef7f22cf0c/6c738/2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHNUZzOaK//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIQP/2gAIAQEAAQUC2TXKpYkR0aTP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEqv//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECH/2gAIAQEABj8CVDlLfcFI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyG8IMxUiO+YKLGpTYL7EsPTJ//aAAwDAQACAAMAAAAQ9N//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECHFs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEs//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QEigXnKwDZJsQr1uBYAg4Q3EN6DZ2J6y+oeRgw6rs/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/e93cc/DSC07454.webp 300w", "/static/1955505b6f7aa1de89f88179df623995/b0544/DSC07454.webp 600w", "/static/1955505b6f7aa1de89f88179df623995/68fc1/DSC07454.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/73b64/DSC07454.jpg 300w", "/static/1955505b6f7aa1de89f88179df623995/3ad8d/DSC07454.jpg 600w", "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHP2i4BFf/EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMQEf/aAAgBAQABBQKLW7jOnP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQIBAT8BNxf/xAAYEAACAwAAAAAAAAAAAAAAAAAAEAEiMf/aAAgBAQAGPwIrD1f/xAAZEAEAAwEBAAAAAAAAAAAAAAABABARMYH/2gAIAQEAAT8hm4VOTjQBgj2v/9oADAMBAAIAAwAAABD0z//EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QE1f/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAgEBPxCA/8QAGhABAQACAwAAAAAAAAAAAAAAAREAECExQf/aAAgBAQABPxDjHNqAh7iKDKM06EPYIuv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/e93cc/4.webp 300w", "/static/1fd95edea965a3eb4196ffd09162346f/b0544/4.webp 600w", "/static/1fd95edea965a3eb4196ffd09162346f/68fc1/4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1fd95edea965a3eb4196ffd09162346f/73b64/4.jpg 300w", "/static/1fd95edea965a3eb4196ffd09162346f/3ad8d/4.jpg 600w", "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1fd95edea965a3eb4196ffd09162346f/6c738/4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAci0GJCh/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECEQMx/9oACAEBAAEFApnS4xeHOmm2zT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAEAAgMAAAAAAAAAAAAAAAAAETEBECH/2gAIAQEABj8CVGVuJ1//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhQVFxkf/aAAgBAQABPyEsfKmAntILgR5HSqairSrL8T//2gAMAwEAAgADAAAAEFMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBkf/aAAgBAQABPxAEqquBsBOoaD84ksgV+qJXgFsESHVY3vBP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/e93cc/5.webp 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/b0544/5.webp 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/68fc1/5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bf4a286d8b2c8d7f810a0a89817d0221/73b64/5.jpg 300w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/3ad8d/5.jpg 600w", "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bf4a286d8b2c8d7f810a0a89817d0221/6c738/5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`A mineração de ouro é a força motriz da economia do Suriname, um pequeno país no canto nordeste da América do Sul. No papel, o Suriname produziu cerca de 32.800 quilos de ouro em 2019 e mais da metade disso veio da mineração artesanal e de pequena escala. O ouro é responsável por mais de 80% da receita do Suriname em exportações.`}</p>
    <p>{`Tal como na Guiana, a indústria do ouro no Suriname é sustentada pelo mercado negro do mercúrio, o metal tóxico usado no processo de extração. O país usa mais de 50 toneladas de mercúrio ao ano, e os especialistas acreditam que toda essa quantia entra hoje ilegalmente no país. A poluição por mercúrio é galopante em todo o país, da capital litorânea até remotas bacias hidrográficas do interior, mas, sem a assistência do governo, os pequenos mineradores geralmente precisam escolher entre sustentar as redes de tráfico de mercúrio ou perder seus meios de subsistência.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tal como na Guiana, a indústria do ouro no Suriname é sustentada pelo mercado negro do mercúrio`}</p>
    </blockquote>
    <iframe src="https://datawrapper.dwcdn.net/0dj3l/2/"></iframe>
    <hr></hr>
    <p>{`Nas imagens de satélite, o Suriname é quase totalmente verde. A selva cobre 93% do país, tornando-o uma das nações mais densamente florestadas do mundo. Mas uma moderna corrida pelo ouro está ameaçando as exuberantes florestas do país.`}</p>
    <p>{`Por toda a floresta tropical, muitas comunidades dependem da mineração, incluindo os Maroons, descendentes de escravos africanos que escaparam das plantações holandesas no litoral, estabelecendo-se no interior e que possuem fortes conexões culturais com a mineração e o ouro. O aumento vertiginoso dos preços do ouro no início do século 21 levou a uma rápida expansão da indústria da mineração, especialmente no território quilombola. Projetos legais e ilegais de extração de ouro se tornaram os maiores responsáveis pelo desmatamento no Suriname. O desmatamento anual no Suriname aumentou 12% em 2018, sendo a quinta maior taxa de desmatamento no mundo.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Projetos legais e ilegais de extração de ouro se tornaram os maiores responsáveis pelo desmatamento no Suriname.`}</p>
    </blockquote>
    <Video url="https://youtube.com/embed/B-6C1u8KqHw" mdxType="Video" />
    <p>{`O impacto da indústria é maior em assentamentos de fácil acesso, como Brownsweg, uma pequena vila maroon no interior do Suriname. A maioria da população está envolvida na mineração. À noite, alguns poucos garimpeiros mais abastados aparecem para festejar em frente aos supermercados chineses e às lojas de bebidas alcóolicas, perambulando com seus Lexus ES 360 na selva. Mas a maioria deles são garimpeiros de subsistência e fazem de tudo para se virar.`}</p>
    <p>{`Em um garimpo a uma hora de Brownsweg, um dos líderes da comunidade marron local, chamados de capitães, anda pelas crateras de cor ocre, que formam uma cicatriz na floresta verde. Com chinelos esportivos e uma Pangi, a roupa tradicional  marron usada sobre seu ombro direito, “Capy” explica que seus garimpeiros usam maquinário pesado para escavar as minas, mas precisam do mercúrio para separar o ouro da lama.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdsljlqI4//EABsQAAIDAAMAAAAAAAAAAAAAAAADAQIRBBMj/9oACAEBAAEFAnM86tsq082wyew2TD//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAFBEBESIVGR/9oACAEBAAY/Ankl6cINwWp//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8hA5iVnaLAovhkA4RewHwTJtYTZdz/2gAMAwEAAgADAAAAEOw//8QAFxEAAwEAAAAAAAAAAAAAAAAAACExUf/aAAgBAwEBPxBKLD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8Qta//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGB/9oACAEBAAE/EA1Jwdq5Qw7dlRs5XyHSqBvbfexNHCmBm8jckhoWwgCsLJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/e93cc/slider2-1.webp 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/b0544/slider2-1.webp 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/68fc1/slider2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/ebb05ccd492c268a66c762bf7d8cafa3/73b64/slider2-1.jpg 300w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/3ad8d/slider2-1.jpg 600w", "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ebb05ccd492c268a66c762bf7d8cafa3/6c738/slider2-1.jpg",
              "alt": "Em povoados de fácil acesso como Brownsweg, um pequeno vilarejo marron no interior do Suriname. A maioria da população está envolvida na mineração.",
              "title": "Em povoados de fácil acesso como Brownsweg, um pequeno vilarejo marron no interior do Suriname. A maioria da população está envolvida na mineração.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Em povoados de fácil acesso como Brownsweg, um pequeno vilarejo marron no interior do Suriname. A maioria da população está envolvida na mineração.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEU5uyaSGH/xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDEgQTFP/aAAgBAQABBQL12Lk2duVikl6m7P/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGJj//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGrr//EABwQAAIABwAAAAAAAAAAAAAAAAAQASExQlGBkf/aAAgBAQAGPwK0pDhjSkv/xAAcEAEBAAICAwAAAAAAAAAAAAABABEhMUFRgbH/2gAIAQEAAT8hGQ+JOlvadwwPEFecSHrKX//aAAwDAQACAAMAAAAQxO//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxALL//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EFkv/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBcZHR8f/aAAgBAQABPxBaAU3f3EEOF4THmL2o18WYEqbrmKhYD3Fbd9z/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/e93cc/slider2-2.webp 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/b0544/slider2-2.webp 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/68fc1/slider2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b1d5a36bb569a43f9c3403275a2f4e34/73b64/slider2-2.jpg 300w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/3ad8d/slider2-2.jpg 600w", "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b1d5a36bb569a43f9c3403275a2f4e34/6c738/slider2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGZrmSrAaIn/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEiEx/9oACAEBAAEFAihMgFOEfNtZ/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARAhEf/aAAgBAQAGPwJCIysk4z//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFx/9oACAEBAAE/Ia8LLxyWJRsniBGUSgYYbSf/2gAMAwEAAgADAAAAEKv/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQMBAT8QSQmH/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EKx//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAITGRsf/aAAgBAQABPxCdeU9140E9PNRHUWT5mPQOvmam5aF1GIDgV3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/e93cc/slider2-3.webp 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/b0544/slider2-3.webp 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/68fc1/slider2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/7e7ac88459187d99aaa9ace549d0e89b/73b64/slider2-3.jpg 300w", "/static/7e7ac88459187d99aaa9ace549d0e89b/3ad8d/slider2-3.jpg 600w", "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7e7ac88459187d99aaa9ace549d0e89b/6c738/slider2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABmzMIYLKf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxET/9oACAEBAAEFAujO2krZJtFsEbh//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAS/9oACAEDAQE/AcBZL//EABcRAAMBAAAAAAAAAAAAAAAAAAABEgL/2gAIAQIBAT8BtlaP/8QAHRAAAQIHAAAAAAAAAAAAAAAAAAIREBIhMkGRof/aAAgBAQAGPwKqOjS7LUwyMf/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExcZH/2gAIAQEAAT8hvIKEBxFOr9ZabikVUwm3Z//aAAwDAQACAAMAAAAQU+//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QNCv/xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFh/9oACAECAQE/EJ6bP//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACExQVFhgaH/2gAIAQEAAT8QrNY9cc2TPIHuc45VBYMPtwZ6PbDeL/GkoB+YMgCGnln/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/e93cc/slider2-4.webp 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/b0544/slider2-4.webp 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/68fc1/slider2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/357b3d16b1f9dcbf61dd30c120d707c9/73b64/slider2-4.jpg 300w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/3ad8d/slider2-4.jpg 600w", "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/357b3d16b1f9dcbf61dd30c120d707c9/6c738/slider2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABrKec04JB/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAESAhET/9oACAEBAAEFAvXZe2OyelMeun//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARIh/9oACAEDAQE/Aawx/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwG0tf/EABkQAAIDAQAAAAAAAAAAAAAAAAABIDIzQv/aAAgBAQAGPwKqRyjSH//EABoQAQADAAMAAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8h1OV3EV3tRkpdB5UB5e5iF8NxVbP/2gAMAwEAAgADAAAAEJAP/8QAFxEBAAMAAAAAAAAAAAAAAAAAABFRYf/aAAgBAwEBPxDBFH//xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBOsnZ//8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8QqXKwtVl5zkZUU18xtEexE+YUpQ32wl+vXezJ93Pc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/e93cc/slider2-5.webp 300w", "/static/1211945c12409677b43af1b702c5ee14/b0544/slider2-5.webp 600w", "/static/1211945c12409677b43af1b702c5ee14/68fc1/slider2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1211945c12409677b43af1b702c5ee14/73b64/slider2-5.jpg 300w", "/static/1211945c12409677b43af1b702c5ee14/3ad8d/slider2-5.jpg 600w", "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1211945c12409677b43af1b702c5ee14/6c738/slider2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <Sidenote side="left" title="" mdxType="Sidenote">
	Cerca de 98% dos garimpeiros do Suriname usam mercúrio, que se liga aos pequenas fagulhas de ouro misturados à água e à lama
    </Sidenote>
    <p>{`É o mercúrio que faz girar as economias de mineração locais. Cerca de 98% dos garimpeiros do Suriname usam mercúrio, que se liga aos pequenas fagulhas de ouro misturados à água e à lama, que são eliminadas das minas. Sem o  azougue líquido, os garimpos artesanais seriam incapazes de extrair de forma eficiente o ouro preso no solo da selva, de acordo com Jessica Naarendorp, diretora financeira da NANA Resources, uma empresa surinamesa que anteriormente extraía ouro, mas que agora explora principalmente para empresas de mineração nacionais e estrangeiras.`}</p>
    <p>{`Para cada quilograma de ouro extraído, são utilizados aproximadamente três quilogramas de mercúrio, e a maior parte é liberada no frágil ecossistema da Amazônia.`}</p>
    <hr></hr>
    <p>{`Mais de 100 quilômetros ao norte da mina do Capy, Paul Ouboter, biólogo e diretor do `}<a parentName="p" {...{
        "href": "https://www.neowild.org/"
      }}>{`Institute for Neotropical Wildlife and Environmental Studies no Suriname (também conhecido como NeoWild)`}</a>{`, senta-se no terraço da sua casa em Paramaribo. Ele traça na tela do seu laptop as vias fluviais do país nos mapas. Grande parte do mercúrio liberado pelo setor de mineração acaba se acumulando nos rios da região, segundo Ouboter. De lá, o mercúrio acaba chegando à cadeia alimentar. Comunidades indígenas, como os Maroons, que dependem em grande parte do peixe na sua dieta alimentar, estão expostas a níveis particularmente altos de mercúrio.`}</p>
    <Video url="https://youtube.com/embed/-0sUAudv6CA" mdxType="Video" />
    <p>{`Pesquisas mostram que quase metade dos peixes predadores pescados no Suriname apresenta níveis elevados de mercúrio. "Isso é um problema, porque as pessoas gostam de comer peixes grandes", ele diz com um sorriso largo. "Não é fácil mudar os hábitos alimentares das pessoas, mas é o que precisamos fazer". Peixe lobo gigante, piranhas e vários bagres deveriam ser evitados no cardápio do Suriname, de acordo com Ouboter.`}</p>
    <p>{`Mas o mercúrio não é transportado apenas pela água e pelos peixes, ele também viaja pelo ar, após sua evaporação das superfícies da água e da vegetação, ou depois que os garimpeiros queimam o mercúrio do amálgama no local. "O que acontece então é que o metal segue com os ventos alísios do nordeste para o oeste e depois é depositado novamente, especialmente quando chove", diz Ouboter. O mercúrio suspenso no ar pode chegar a regiões sem nenhum garimpo, como a bacia superior do rio Coppename.`}</p>
    <p>{`Ouboter está solicitando a proibição imediata do mercúrio, apesar de advertir que é impossível a reversão dos danos causados aos sistemas fluviais. O mercúrio que já se encontra no ecossistema permanecerá por lá durante séculos.`}</p>
    <hr></hr>
    <p>{`Capy, o garimpeiro em Brownsweg, está ciente do impacto ambiental da sua operação, mas afirma que isso não é nada, se comparado às corporações internacionais, que escavam mais profundamente do que os garimpeiros de pequena e média escalas e que aplicam cianeto tóxico no seu processo de extração. A poluição por cianeto, embora não tão persistente quanto a contaminação por mercúrio, é ainda uma grave ameaça à vida selvagem e à saúde humana. "A meu ver, o governo possui grandes interesses nessas grandes empresas", ele diz, "então eles não se importam".`}</p>
    <Video url="https://youtube.com/embed/ii6Bi2Zy3Ng" mdxType="Video" />
    <p>{`As duas grandes multinacionais estrangeiras de mineração no Suriname, IAMGOLD e Newmont Mining, utilizam um processo chamado lixiviação por cianeto, que consegue extrair concentrações mais finas de ouro do solo. Com esses métodos avançados, os investidores estrangeiros podem trabalhar em terrenos anteriormente minerados pelos Maroons, que conseguem capturar apenas cerca de 30% do ouro disponível.`}</p>
    <p>{`O capitão maroon reclama que as minas não são a única fonte de poluição por mercúrio. As lojas de ouro em Paramaribo, ele argumenta, usam as mesmas técnicas para queimar o mercúrio e isolar o ouro. "Na cidade, eles acham que nós, residentes do interior, não somos bons, nada do que fazemos é bom", diz ele. “Mas quando trazemos nosso ouro para a cidade, a mesma coisa acontece lá. E ninguém pode me dizer que isso não é prejudicial”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Na cidade, eles acham que nós, residentes do interior, não somos bons, nada do que fazemos é bom"`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Paramaribo, a pequena e agitada capital do Suriname, é um caldeirão de etnias. Antigas casas coloniais holandesas e uma cozinha internacional fazem da cidade um destino para turistas, a maioria chegando em voos diretos da Holanda. Apesar da sua pequena população de 586.000 habitantes, o país não possui uma etnia dominante. Trabalhadores indianos e chineses, trazidos depois do fim da escravidão em 1863, constituem  atualmente mais de um quarto da população do Suriname. O idioma oficial é o holandês, e o idioma comum é o Sranan Tongo, crioulo de base holandesa, inglesa e portuguesa, mas o português é dominante na parte norte da cidade, em um bairro chamado Little Belém - em homenagem à cidade brasileira localizada a mais de 1.000 km de distância de Paramaribo.`}</p>
    <Video url="https://youtube.com/embed/R4kwzxuetgE" mdxType="Video" />
    <p>{`Little Belém caracteriza-se por ter muitos supermercados e fachadas de lojas brasileiras anunciando em português a "compra de ouro". A comunidade de migrantes foi construída em décadas de migração da mão de obra de brasileiros em busca de melhores perspectivas econômicas. A maioria deles encontrou trabalho como garimpeiros, mineradores ilegais de ouro ou operando o comércio nas lojas de ouro de Paramaribo, que despejam vapor de mercúrio sobre o ar da cidade pelas chaminés dos telhados.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Em Paramaribo, vimos medições seis a doze vezes mais altas do que nos campos auríferos"`}</p>
    </blockquote>
    <p>{`Dennis Wip, professor sênior na Universidade do Suriname, especialista em poluição por mercúrio, alerta que essas chaminés podem ser lentos assassinos.`}</p>
    <p>{`"A poluição por mercúrio é um grande problema no Suriname", diz Wip, e não apenas em locais remotos de mineração, como muitas pessoas supõem. "Na cidade de Paramaribo, a poluição é bastante alta", diz ele. Ocasionalmente, as emissões na cidade das lojas de ouro elevam os níveis atmosféricos de mercúrio para 30.000 nanogramas por metro cúbico, significativamente mais altos do que os padrões considerados seguros pela União Europeia e pelos Estados Unidos, segundo o cientista. "Em Paramaribo, vimos medições seis a doze vezes mais altas do que nos campos auríferos", diz ele. "Isso significa que precisamos cuidar dos negócios de ouro da cidade".`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4zdxiSL/AP/EABgQAAMBAQAAAAAAAAAAAAAAAAABAhEi/9oACAEBAAEFAsTdYLCUVHOH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAAQESH/2gAIAQEABj8CMdq//8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERMWFxof/aAAgBAQABPyFVU89HibEjDlIJodj/2gAMAwEAAgADAAAAEPvf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAEDAQE/EEY//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABsQAQEBAAMBAQAAAAAAAAAAAAERADFBUSFx/9oACAEBAAE/EKoHKIv5Jn0JRzo9S9t5xqxCRHzAUkemV8nXf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/e93cc/DSC07471.webp 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/b0544/DSC07471.webp 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/68fc1/DSC07471.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/eb9ccd46747fc591122a9a0c36a72dcf/73b64/DSC07471.jpg 300w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/3ad8d/DSC07471.jpg 600w", "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/eb9ccd46747fc591122a9a0c36a72dcf/6c738/DSC07471.jpg",
            "alt": "As emissões das lojas de ouro elevam os níveis de mercúrio atmosférico em Paramaribo.",
            "title": "As emissões das lojas de ouro elevam os níveis de mercúrio atmosférico em Paramaribo.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`As emissões das lojas de ouro elevam os níveis de mercúrio atmosférico em Paramaribo.`}</em></p>
    <p>{`Os equipamentos das lojas de ouro, especialmente as retortas, que são dispositivos de captura de mercúrio, são geralmente disfuncionais ou estão em mau estado, de acordo com Wip. A maioria dos funcionários das lojas de ouro consultada por esta reportagem não estava ciente dos danos causados pela contaminação por mercúrio ou subestimava seu impacto, o que os especialistas atribuem à “cultura machista” da indústria, à falta de acesso aos medicamentos e à natureza de ação lenta da toxina.`}</p>
    <p>{`Sem melhores equipamentos e educação sobre os riscos, as emissões de mercúrio em Paramaribo poderiam continuar a aumentar, enquanto os preços do ouro continuam a subir e mais do precioso metal segue seu caminho até as lojas de ouro da capital.`}</p>
    <hr></hr>
    <p>{`Em 2018, O Suriname ratificou a Convenção de Minamata sobre Mercúrio, um tratado global projetado para reduzir a poluição por mercúrio, em parte pela eliminação do seu uso na extração do ouro. Mas o mercúrio continua chegando em grande quantidade por suas fronteiras, principalmente pelos canais ilegais. Embora o mercúrio possa ser legalmente importado com uma licença, nenhuma permissão foi concedida desde 2004.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.iucn.nl/files/groene_economie/regional_mercury_report_suriname.pdf"
      }}>{`Estima-se que 56 toneladas`}</a>{` de mercúrio foram utilizadas em 2018 no Suriname, o ano mais recente para o qual há dados disponíveis, e várias lojas de ferramentas de mineração, operadas por proprietários brasileiros, indianos e chineses, confirmam que conseguem arranjar mercúrio ao cliente. "A ampla disponibilidade de mercúrio no Suriname e o fato de que nenhuma importação legal de mercúrio ocorreu em mais de 15 anos provocam a suspeita de que todo o mercúrio do mercado entrou no país de forma ilegal", diz Marieke Heemskerk, uma antropóloga com um histórico de uma longa trajetória pesquisando o setor do ouro no Suriname.`}</p>
    <p>{`A princípio, nenhum dos lojistas divulgaria como adquiriu o mercúrio, mas acaba não sendo difícil descobrir, se você sabe a quem perguntar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeLFbHImR//EABgQAQEBAQEAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFApjUO5PThzS+yrPf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERIUEQIv/aAAgBAQAGPwLy+WmVo5xkJn//xAAaEAEAAgMBAAAAAAAAAAAAAAABACERMVFh/9oACAEBAAE/IcMgMxnl27Jxb5EJTayPVPY3GcrG5//aAAwDAQACAAMAAAAQE/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCVj//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFhof/aAAgBAQABPxBzIcnnDe5WgSEzUaNsNHB/iAP3J5WVDRZk+DIHr1n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/e93cc/DSC07613.webp 300w", "/static/b7982542053e6174008af7df5977506e/b0544/DSC07613.webp 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/b7982542053e6174008af7df5977506e/73b64/DSC07613.jpg 300w", "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/b7982542053e6174008af7df5977506e/3ad8d/DSC07613.jpg",
            "alt": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "title": "Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Wrapped inside a newspaper a mercury dealer reveals a small, white bottle filled with a heavy and silvery liquid. “It comes from Guyana,” the man says.`}</em></p>
    <p>{`Por volta das seis da tarde, os bares próximos às lojas de ouro de Little Belém e as ruas de Paramaribo começam a ficar mais barulhentos, à medida que o sol se põe sobre a cidade. Um motorista de táxi indiano dá um gole nervoso em uma lata de meio litro de cerveja Heineken, enquanto fica ao lado do seu táxi na Anamoestraat, a principal rua de Little Belém. Quando questionado sobre o mercúrio, ele se oferece para ir a um posto de gasolina próximo, para fazer uma ligação rápida para um traficante. Ele desliga e anuncia que um quilograma de mercúrio custará US$ 110 e que um vendedor está a caminho.`}</p>
    <p>{`Dentro de alguns minutos, aparece outro carro. O motorista abaixa a janela e segura um jornal enrolado que trouxe com ele. "Vem da Guiana", diz o homem. Embrulhada no seu interior está uma pequena garrafa branca cheia de um líquido pesado e prateado. O traficante destampa a garrafa de plástico e despeja uma pequena quantidade de prata-viva na tampa da garrafa, para provar que ele tem o produto. Quando perguntado sobre quanto mais mercúrio ele poderia conseguir, ele pergunta: "Quanto você quer?" Parece não haver escassez desse produto, e nem um único carregamento de mercúrio foi confiscado na rota da Guiana desde 2014.`}</p>
    <hr></hr>
    <p>{`Nem todo o mercúrio ilícito do país vem da Guiana, de acordo com uma fonte em Paramaribo, que concordou em dar entrevista, com a condição de que só seria identificado como um anônimo "empresário do ouro". Em viagens regulares ao sul para trabalhar nas minas no interior do país, ele aprendeu que existem outras rotas por onde o mercúrio entra no país. Durante a entrevista, ele liga para o seu comerciante de equipamentos de mineração chinês-surinamês para obter mais informações.`}</p>
    <p>{`O comerciante chinês-surinamês afirma que os chineses fornecem agora a maior parte do mercúrio ao país. As lojas compram contêineres de mercúrio por US$ 3000 e os vendem por US$ 3200, com um lucro de US$ 200. "Se vem da China, só é necessário passar por um local", diz o empresário. "O porto `}{`[marítimo]`}{`."`}</p>
    <p>{`Um estudo de 2020 da Union for Conservation of Nature National Committee of the Netherlands (`}<a parentName="p" {...{
        "href": "https://www.iucn.nl/files/groene_economie/lr_mercury_brochure_digitaal_gebruik.pdf"
      }}>{`IUCN NL`}</a>{`) descobriu que nenhuma remessa ilegal de mercúrio vinda da China foi interceptada, mas informantes disseram aos investigadores que as autoridades alfandegárias geralmente recebem propinas para permitir que mercadorias ilícitas entrem no país.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylqzgND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaNzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcAvEcejSfjsyL3leFn//aAAwDAQACAAMAAAAQIO//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QZH//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRcZGh/9oACAEBAAE/EEN13ZA6r7FqMjgviUyAKq2PcSm5gqBBYtyqrtXLP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c3a57de69e28c775619b2f2eefa30006/e93cc/ouro.webp 300w", "/static/c3a57de69e28c775619b2f2eefa30006/b0544/ouro.webp 600w", "/static/c3a57de69e28c775619b2f2eefa30006/90807/ouro.webp 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c3a57de69e28c775619b2f2eefa30006/73b64/ouro.jpg 300w", "/static/c3a57de69e28c775619b2f2eefa30006/3ad8d/ouro.jpg 600w", "/static/c3a57de69e28c775619b2f2eefa30006/2ee6c/ouro.jpg 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c3a57de69e28c775619b2f2eefa30006/2ee6c/ouro.jpg",
            "alt": "Imagem Map Suriname.jpg Crédito IUCN",
            "title": "Imagem Map Suriname.jpg Crédito IUCN",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`{TROCAR ESTA IMAGEM} Imagem Map Suriname.jpg Crédito IUCN`}</em></p>
    <p>{`De volta à “backtrack”, como são chamadas as travessias fluviais informais entre a Guiana e o Suriname, um traficante surinamês com seu barco zela por todo o rio Corentyne, próximo de Nieuw Nickerie. Ele afirma que o mercúrio é levado do Suriname para a Guiana e que as rotas de contrabando são muito fugazes. Ele próprio transportou mercúrio pelo rio na direção oposta, da Guiana para o Suriname, em embalagens em tamanho industrial até aproximadamente cinco meses atrás. "As pessoas compram as garrafas grandes... e embalam em garrafas menores para negociar e vender mais rapidamente", diz o traficante.`}</p>
    <Video url="https://youtube.com/embed/wlKJb1KKJCc" mdxType="Video" />
    <p>{`A corrupção, a rápida mudança do modus operandi e a falta de recursos frustram alguns dos policiais bem-intencionados do Suriname. "A fronteira está aberta", diz um policial local da municipalidade de Nieuw Nickerie. Vários pontos de passagem oferecem amplas oportunidades para os contrabandistas. Nas margens surinamesas do rio, existem muitas invasões e canais informais, facilitando uma entrada fácil. “Não podemos ter olhos para todos os lugares ao mesmo tempo", diz ele, acrescentando que os contrabandistas estão “se tornando cada vez mais inteligentes".`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Não podemos ter olhos para todos os lugares ao mesmo tempo", diz ele, acrescentando que os contrabandistas estão “se tornando cada vez mais inteligentes".`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Capy, o proprietário maroon, administra sua mina perto de Brownsweg há 15 anos. Assim como a maioria dos garimpeiros entrevistados para esta reportagem, ele espera abandonar o mercúrio, mas falta orientação adequada. "Nós estamos dispostos. Se eles dizem que há outra maneira, queremos trabalhar com ela”, diz Capy. “Mas eles `}{`[o governo]`}{` não dizem nada; eles não nos ensinam nada".`}</p>
    <p>{`O argumento do minerador é ecoado por Naarendorp. "Ninguém pode ser contra Minamata", diz ela, "mas no momento em que você diz que alguma coisa será proibida, precisa apresentar uma alternativa."`}</p>
    <Video url="https://youtube.com/embed/9uuO-lK0GnE" mdxType="Video" />
    <p>{`Muitos dos garimpeiros do interior são trabalhadores esforçados, mas sem instrução, de acordo com Naarendorp. Ela argumenta que os garimpeiros precisam não apenas ser educados, mas também ser convencidos de métodos alternativos ou seguros, que geralmente são muito caros ou de difícil acesso. "Todos nós queremos ser sustentáveis", diz ela, "mas isso deve ser acessível."`}</p>
    <p>{`Capy admite que precisam comprar mercúrio no mercado negro. “Não existe um local específico onde possa ser comprado, porque é proibido, assim como a cocaína, a maconha e o ecstasy. Esse tipo de coisa que você só encontra na rua porque é uma coisa proibida".`}</p>
    <p><em parentName="p">{`*`}{`Alguns nomes foram mudados para proteger as identidades dos entrevistados.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      